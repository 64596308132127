<template>
	<div class="add_update_org">
		<jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
			<div>
				<el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
					<el-form-item label="机构名称:" prop="orgNa">
						<el-input v-model="diaform.orgNa"></el-input>
					</el-form-item>
					<el-form-item label="上级机构:" prop="pOrgNa">
						<div @click="selectInstitutions">
							<el-input placeholder="请选择" v-model="diaform.pOrgNa">
								<el-button slot="append" icon="el-icon-circle-close" @click.stop="clear"></el-button>
							</el-input>
						</div>
					</el-form-item>
					<el-form-item label="机构类型:" prop="type" :label-width="formLabelWidth">
						<el-radio v-model="diaform.type" label="0">机构</el-radio>
						<el-radio v-model="diaform.type" label="1">车队</el-radio>
						<el-radio v-model="diaform.type" label="2">线路</el-radio>
						<el-radio v-model="diaform.type" label="3">调度室</el-radio>
					</el-form-item>
					<el-form-item label="所属省市:" prop="provinceOptions" :label-width="formLabelWidth">
						<el-cascader
							:options="options"
							v-model="diaform.provinceOptions"
							@change="handleChange"
							placeholder="请选择所属省市"
						></el-cascader>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			:showType="['0', '1']"
			:disableOrgNum="oldOrgNum"
			selectType="0"
			title="选择上级机构"
		></institutions-tree>
	</div>
</template>
<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import { provinceAndCityData } from "element-china-area-data";
export default {
	data() {
		return {
			oldOrgNum: "",
			options: provinceAndCityData,
			type: "",
			title: "",
			diaform: {
				orgNa: "",
				pOrgNa: "",
				pOrgId: "",
				type: "0",
				provinceOptions: [],
				province: "",
				bCity: ""
			},
			rules: {
				orgNa: [
					{
						required: true,
						message: "请输入机构名称",
						trigger: "blur"
					}
				],
				type: [
					{
						required: true,
						message: "请选择机构类型",
						trigger: "blur"
					}
				],
				provinceOptions: [
					{
						required: true,
						message: "请选择所属省市",
						trigger: "blur"
					}
				]
			},
			dialogVisible: false,
			formLabelWidth: "100px"
		};
	},
	activated() {},
	components: {
		institutionsTree
	},
	watch: {
		type: function (val) {
			let t = "";
			switch (val) {
				case "add":
					t = "新增机构";
					break;
				case "update":
					t = "修改机构";
					break;
				default:
					t = "新增机构";
			}
			this.title = t;
		},
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("dialogForm");
			}
		}
	},
	methods: {
		init(option, type) {
			this.type = type ? type : "add";
			this.dialogVisible = true;
			if (option) {
				this.$nextTick(() => {
					this.diaform = JSON.parse(JSON.stringify(option));
					this.oldOrgNum = this.diaform.num;
					if (this.type != "add") {
						this.getProvince(this.diaform);
					}
				});
			}
		},
		getProvince(option) {
			let provinceAndCiti = [];
			this.options.forEach(item => {
				if (item.label == option.province) {
					this.diaform.province = item.label;
					provinceAndCiti.push(item.value);
					item.children.forEach(e => {
						if (e.label == option.bCity) {
							this.diaform.bCity = e.label;
							provinceAndCiti.push(e.value);
						}
					});
				}
			});
			this.diaform.provinceOptions = provinceAndCiti;
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.diaform.pOrgId = "0";
			this.oldOrgNum = "";
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		// 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级机构查询
		addInstitutions(data) {
			console.log(data);
			this.diaform.pOrgId = data.orgId;
			this.diaform.pOrgNa = data.orgNa;
		},
		clear() {
			this.diaform.pOrgId = "";
			this.diaform.pOrgNa = "";
		},
		handleChange(value) {
			let provinceAndCiti = [];
			if (value) {
				this.options.forEach(item => {
					if (item.value == value[0]) {
						this.diaform.province = item.label;
						provinceAndCiti.push(item.value);
						item.children.forEach(e => {
							if (e.value == value[1]) {
								this.diaform.bCity = e.label;
								provinceAndCiti.push(e.value);
							}
						});
					}
				});
			}
			this.diaform.provinceOptions = provinceAndCiti;
		},
		save() {
			this.$refs.dialogForm.validate(valid => {
				if (valid) {
					let url = "/sys/org/";
					if (this.type == "add") {
						url = url + "add";
					} else {
						url = url + "update";
					}
					let option = JSON.parse(JSON.stringify(this.diaform));
					this.$http.post(url, option, { isRequestParam: false }).then(res => {
						this.$message({
							type: "success",
							message: res.msg
						});
						this.dialogVisible = false;
						this.$emit("updateList");
					});
				} else {
					return false;
				}
			});
		}
	}
};
</script>
