<template>
	<div id="org_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="orgNa" label="机构名称:">
				<el-input placeholder="请输入" v-model="form.orgNa"></el-input>
			</jy-query-item>
			<jy-query-item prop="pOrgNa" label="上级机构:">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="form.pOrgNa"></el-input>
				</div>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsOrganizationManagementList')"
					>查询</el-button
				>
				<el-button
					type="primary"
					plain
					@click="resetForm('form')"
					v-if="btnexist('scheduleSystemSettingsOrganizationManagementReset')"
					>重置</el-button
				>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleSystemSettingsOrganizationManagementInsert')"
					>新增</el-button
				>
				<!-- <el-button type="primary" @click="handleBatchDelete">批量删除</el-button>
                    <el-button type="primary">导出</el-button> -->
			</template>
		</jy-query>
		<div class="table-list">
			<div class="table-item" v-for="(item, index) in dataList" :key="index">
				<p class="org-name">{{ item.orgNa }}</p>
				<div class="content-area">
					<div class="content-item">
						<span class="label-text">上级机构：</span>
						<span class="value-text">{{ item.pOrgNa }}</span>
					</div>
					<div class="content-item">
						<span class="label-text">机构类型：</span>
						<span class="value-text">{{ item.type | orgtype }}</span>
					</div>
					<div class="content-item">
						<span class="label-text">创建日期：</span>
						<span class="value-text">{{ item.createT }}</span>
					</div>
				</div>
				<div class="ope-btn">
					<el-button
						v-if="btnexist('scheduleSystemSettingsOrganizationManagementUpdate') && item.orgId !== '0'"
						type="primary"
						plain
						size="mini"
						@click="addUdate(item)"
						>修改</el-button
					>
					<el-button
						v-if="btnexist('scheduleSystemSettingsOrganizationManagementUpdate') && item.orgId !== '0'"
						plain
						size="mini"
						@click="handleDelete(item)"
						>删除</el-button
					>
				</div>
			</div>
		</div>
		<!-- <jy-table max-height="595" ref="jyTable" :data="dataList" @select="selectchange" @select-all="selectallchange">
			<jy-table-column type="index" label="序号" width="60" fixed="left"></jy-table-column>
			<jy-table-column prop="orgNa" label="机构名称" fixed="left" min-width="160"></jy-table-column>
			<jy-table-column prop="pOrgNa" label="上级机构" fixed="left" min-width="160"></jy-table-column>
			<jy-table-column label="机构类型" min-width="120">
				<template slot-scope="scope">
					{{ scope.row.type | orgtype }}
				</template>
			</jy-table-column>
			<jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table> -->
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:page-sizes="pageSizes"
			:total="total"
		>
		</jy-pagination>
		<add-update-org ref="addUpdateOrg" @updateList="onupdate"></add-update-org>
		<institutions-tree
			ref="institutions"
			:showType="['0', '1']"
			@addInstitutions="addInstitutions"
			title="选择上级机构"
		></institutions-tree>
	</div>
</template>
<script>
import { mapMutations } from "vuex";
import addUpdateOrg from "@/components/pages/admin/systemSettings/organizationManagement/addUpdateOrg";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			statusList: [],
			form: {
				orgNa: "",
				pOrgId: "",
				pOrgNa: ""
			},
			pageSize: 15,
			pageSizes: [15, 30, 60, 100],
			total: 0,
			pageIndex: 1,
			dataList: [],
			operateList: [],
			// 批量删除
			batchdeleteList: [],

			btnMenuId: "scheduleSystemSettingsOrganizationManagement"
		};
	},
	mixins: [btnMixins],
	components: {
		addUpdateOrg,
		institutionsTree
	},
	filters: {
		orgtype(val) {
			if (val == "0") {
				return "机构";
			} else if (val == "1") {
				return "车队";
			} else {
				return "线路";
			}
		}
	},
	created() {
		this.getList();
		this.setOperateList();
	},
	methods: {
		...mapMutations(["resetTree"]),
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: row => {
						return this.btnexist("scheduleSystemSettingsOrganizationManagementUpdate") && row.orgId !== "0";
					}
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: row => {
						return this.btnexist("scheduleSystemSettingsOrganizationManagementDelete") && row.orgId !== "0";
					}
				}
				// {
				//     name: '查看',
				//     icon: 'el-icon-view',
				//     fun: this.handlecheck
				// }
			];
			this.operateList = l;
		},
		getList() {
			let option = {
				sysOrg: {
					...this.form
				},
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/sys/org/pageQuery";
			// console.log(option)
			this.$http.post(url, option, { isRequestParam: false }).then(({ detail }) => {
				detail.list.forEach(item => {
					item.createT = this.$util.formatTime(item.createT);
				});
				this.dataList = detail.list;
				console.log(this.dataList);
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 更新表格
		onupdate() {
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.pOrgId = "";
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级机构查询
		addInstitutions(data) {
			// console.log(data)
			this.form.pOrgId = data.orgId;
			this.form.pOrgNa = data.orgNa;
		},
		addUdate(option) {
			if (option) {
				this.$refs.addUpdateOrg.init(option, "update");
			} else {
				this.$refs.addUpdateOrg.init();
			}
		},
		// 批量选择
		selectchange(selection) {
			// console.log(selection)
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			// console.log(selection)
			this.batchdeleteList = selection;
		},
		// 批量删除
		// handleBatchDelete() {
		//     let option = []
		//     this.batchdeleteList.forEach(item => {
		//         option.push(item.orgId)
		//     })
		//     let url = '/system/org/batchDeleteOrg'
		//     let o = {
		//         ordIds: option
		//     }
		//     this.$http.post(url, o).then(res => {
		//         this.$message({
		//             type: 'success',
		//             message: res.msg
		//         })
		//         this.getList()
		//     })
		// },
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该机构?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = row.orgId;
					let url = "/sys/org/delete";
					this.$http.post(url, option, { isRequestParam: false }).then(res => {
						// console.log(res)
						this.$message({
							type: "success",
							message: res.msg
						});
						this.resetTree();
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		}
	}
};
</script>
<style scoped="organizationManagement" lang="scss">
#org_manage {
	.logo_img {
		width: 60px;
		height: 60px;

		i {
			font-size: 60px;
		}
	}
	.table-list {
		display: flex;
		flex-wrap: wrap;
		// justify-content: space-between;
		min-height: 666px;
		.table-item {
			width: 362px;
			height: 212px;
			padding: 18px 20px 16px;
			box-shadow: 0px 0px 15px 0px rgba(14, 41, 73, 0.16);
			border-radius: 8px;
			border: 1px solid rgba(96, 97, 112, 0.16);
			margin-bottom: 10px;
			margin-right: 8px;
			.org-name {
				padding-bottom: 10px;
				font-size: 16px;
				font-weight: 600;
				color: #000000;
				line-height: 22px;
				border-bottom: 1px solid #c9cdd4;
			}
			.content-area {
				padding: 9px 0 22px;
				.content-item {
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #666666;
					line-height: 28px;
					.value-text {
						color: #333333;
						margin-left: 6px;
					}
				}
			}
			.ope-btn {
				// display: flex;
				text-align: right;
			}
		}
	}
}
</style>
